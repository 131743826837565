/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { renderToStaticMarkup } from "react-dom/server";
import moment from "moment";
import { MetricGraph } from "~/types/metricGraph";
import { JSEncrypt } from "jsencrypt";
import { EventSummary } from "~/types/event";

export function dec2hex(dec: number) {
  return "0x" + Number(dec).toString(16).toUpperCase();
}

export function hex2dec(hex: string) {
  return parseInt(hex, 16);
}

export const TimeFormat = "MMM DD, YYYY h:mm a z";
export const ShortTimeFormat = "MMM DD, YYYY h:mm a";
export const DateTimeFormat = "ddd, MMM DD, YYYY hh:mm:ss.SSS a z";
export const GraphAxisDatetimeFormat = "h:mm a<br />MMM D";
export const GraphTooltipDatetimeFormat = "%a, %b %d, %Y %I:%M:%S %P";

export function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

/**
 *  Normalizes a data point to a target range
 * @param data - data point to be normalized
 * @param min - min value of the data
 * @param max - max value of the data
 * @param targetMin - min value of the target range
 * @param targetMax - max value of the target range
 * @returns normalized data point
 */
export function normalizeDataPoint(data: number, min: number, max: number, targetMin: number, targetMax: number) {
  return ((data - min) * (targetMax - targetMin)) / (max - min) + targetMin;
}

/**
 * Convert Array of Objects to Array of Objects grouped by key
 */
export function groupBy<T>(array: T[], property: string) {
  const hash: {
    [key: string]: T[];
  } = {};
  // split the properties into an array by '.'
  const props = property.split(".");
  // loop through the array
  for (let i = 0; i < array.length; i++) {
    // get the current item
    const item = array[i];
    // get the current property value
    const key = props.reduce(function (acc, prop) {
      // if acc is object, get the property value if not, return acc
      return acc && acc[prop];
    }, item as any);
    // if the key is not in the hash, add it
    if (!hash[key]) hash[key] = [];
    // add the current item to the hash
    hash[key].push(item);
  }
  return hash;
}

/**
 * Calc Length of each Array from Object of Arrays (e.g. {a: [1, 2, 3], b: [4, 5, 6]}) and return object of lengths
 * @param {Record<string, any[]} obj e.g. {a: [1, 2, 3], b: [4, 5, 6]}
 * @returns {{ [key : string] : number}} Object of lengths {a: 3, b: 3}
 */
export function calcLengths(obj: Record<string, any[]>): { [key: string]: number } {
  const lengths: { [key: string]: number } = {};
  for (const key in obj) {
    lengths[key] = obj[key as keyof Object].length;
  }
  return lengths;
}

export function svgJSXtoURL(jsx: JSX.Element) {
  const svgString = renderToStaticMarkup(jsx);
  const base64 = btoa(svgString);
  return `data:image/svg+xml;base64,${base64}`;
}

export function encrypt(text: string, publicKeyPem: string): string | null {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKeyPem);

  const encryptedString = encryptor.encrypt(text);
  if (!encryptedString) {
    console.error("Error encrypting string");
    return null;
  }

  return encryptedString; // Base64 encode the encrypted string
}


export function handleOnlyNumber(event: React.KeyboardEvent<HTMLInputElement>) {
  if (event?.key === "-" || event?.key === "+" || event?.key === "." || event?.key.toLowerCase() === "e" ) {
    event.preventDefault();
  }
}
export function handleOnlyNumberForText(event: React.KeyboardEvent<HTMLInputElement>) {
 const regex = new RegExp("^[0-9]");
 const excludeKeys = ['Backspace','Control','Tab']
  console.log(event.key,'----key')
  if(!event.ctrlKey){
    if(event.key && !excludeKeys.includes(event.key)){
      const symbol = event.key;
      if (event?.key === "-" || event?.key === "+" || event?.key === "." || event?.key.toLowerCase() === "e" || !regex.test(symbol)) {
        event.preventDefault();
      }
    }
  }
}

export const getQueryObj = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const queryObj: any = {};
  urlParams.forEach((value, key) => {
    queryObj[key] = value;
  });

  return queryObj;
};

export const getStatusClassName = (val: string) => {
  return val === "Active" ? "text-green" : "text-red";
};

/**
 * Update an object with a nested key with a new value without mutating the original object
 * @param data Data to be updated
 * @param key Key Eg: "a.b.c" , "a" , "a.b"
 * @param value Value to be updated
 * @returns Updated Object
 */
export function updateObj<T>(data: T, key: string, value: string | number | boolean) {
  const newObj = structuredClone(data);
  const keys = key.split(".");
  let currentObj: any = newObj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!currentObj.hasOwnProperty(key) || typeof currentObj[key] !== "object") {
      // If the nested key doesn't exist or it's not an object, create an empty object at the key
      currentObj[key] = {};
    }
    currentObj = currentObj[key];
  }

  // Set the final value at the last key in the nested structure
  currentObj[keys[keys.length - 1]] = value;

  return newObj as T;
}

/**
 * Get the time difference between two dates in seconds
 * @param startTime Start Time in unix timestamp
 * @param endTime End Time in unix timestamp
 * @returns Time difference;
 */
export const getTimeDifference = (startTime: number, endTime = moment().unix()) => {
  const timeDiff = moment.duration(moment.unix(endTime).diff(moment.unix(startTime)));
  return timeDiff;
};

const networkInfo = {
  controlplane: {
    "4G": "MME",
    "5G": "AMF",
  },
  userplane: {
    "4G": "PGW",
    "5G": "UPF",
  },
} as const;

export const getNetworkPlaneInfo = (networkType: "4G" | "5G", plane: keyof typeof networkInfo) => {
  return networkInfo[plane][networkType];
};
export const capitalWithFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const getMetricsList = (selectedTemplate: MetricGraph | null) => {
  return selectedTemplate?.categories.flatMap(category =>
    category.graphs.flatMap(graph =>
      graph.metrics.map(metric => {
        const { name, key } = metric;
        return { name, key };
      })
    )
  ) || [];
};

export const getMetricsTemplateId = (selectedTemplate: MetricGraph | null) => {
  return selectedTemplate?.id || "";
};
// want to create 35 distinct colors for the 35 different CBSDs
export const colorPallete7 = ["#5a189a", "#7b2cbf", "#D163D5", "#edd9a3", "#EBB45B", "#48e5c2", "#3b8ea5"];

export const colorPallete10 = [
  "#f56a00",
  "#fa8c02",
  "#ffad03",
  "#ffc242",
  "#ffcf70",

  "#cea7ee",
  "#b67be6",
  "#9d4edd",
  "#72369d",
  "#461e5c",
];

export const colorPallete15 = [
  "#5d36e7",
  "#6e44ff",
  "#936bff",
  "#b892ff",
  "#dcaaf1",

  "#ffc2e2",
  "#ffa9cb",
  "#ff90b3",
  "#f7859c",
  "#ef7a85",

  "#e97c61",
  "#f4a261",
  "#e9c46a",
  "#8ab17d",
  "#2a9d8f",
];

export const colorPallete30 = [
  "#65010c",
  "#cb1b16",
  "#f26a4f",
  "#e08c2d",
  "#f29479",

  "#fedfd4",
  "#9dcee2",
  "#4091c9",
  "#1368aa",
  "#003CFF",

  "#264653",
  "#287271",
  "#2a9d8f",
  "#8ab17d",
  "#babb74",

  "#e9c46a",
  "#efb366",
  "#f4a261",
  "#e76f51",
  "#ff7b00",

  "#ff9500",
  "#ffaa00",
  "#ffc300",
  "#ffd933",

  "#f992ad",
  "#fbbcee",
  "#fab4c8",
  "#f78ecf",
  "#cfb9f7",
  "#b5d0f7",
];

/**
 * Formats a large number into compact notation and returns the value and notation separately.
 * @param {number} largeNum - The large number to format.
 * @param {boolean} removeSign - Whether to remove the sign of the large number or not. Default is false.
 * @returns {{ value: number, notation: string }} - An object containing the formatted value and notation.
 * @description
 *   - Uses Intl.NumberFormat to format the large number into parts.
 *   - Extracts the value and notation from the formatted parts.
 */
export function formatCompactNumberInParts(largeNum: number, removeSign = false): { value: number; notation: string } {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  const value = removeSign ? Math.abs(largeNum) : largeNum;

  // Get the formatted parts using formatToParts
  const parts = formatter.formatToParts(value);

  // Extract the value and notation
  let formattedValue = "";
  let notation = "";

  for (const part of parts) {
    if (part.type === "integer" || part.type === "fraction" || part.type === "decimal") {
      formattedValue += part.value;
    } else if (part.type === "compact") {
      notation = part.value;
    }
  }

  return { value: +formattedValue, notation };
}

type Operator = "Verizon" | "T-Mobile" | "AT&T";

const VERIZON_COLOR = "#EEO200";
const AT_T_COLOR = "#067AB4";
const TMOBILE_COLOR = "#E20074";

// Variations for each operator name
const operatorVariations: Record<Operator, string[]> = {
  Verizon: ["verizon", "vzn"],
  "T-Mobile": ["t-mobile", "tmobile", "tmob", "tmo"],
  "AT&T": ["at&t", "att", "atandt"],
};

// Color mapping for each operator
const operatorColorMapping: Record<Operator, string> = {
  Verizon: VERIZON_COLOR,
  "T-Mobile": TMOBILE_COLOR,
  "AT&T": AT_T_COLOR,
};

/**
 * Maps an operator name variation to the corresponding color code.
 * @param input - The operator name variation to be mapped.
 * @returns The color code for the operator or null.
 */
export function mapOperatorToColor(input: string): string | null {
  const normalizedInput = input.toLowerCase().replace(/[^a-z]/g, ""); // Convert to lowercase and remove non-alphabetic characters

  for (const operator in operatorVariations) {
    const variations = operatorVariations[operator as Operator].map((variation) =>
      variation.toLowerCase().replace(/[^a-z]/g, "")
    );

    for (const variation of variations) {
      if (variation.includes(normalizedInput) || normalizedInput.includes(variation)) {
        return operatorColorMapping[operator as Operator];
      }
    }
  }

  return null;
}

/**
 *
 * @param role - The role string e.g. READONLY_TENANT
 * @returns The formatted role string e.g. Readonly Tenant
 */
export function roleStringFormatter(role: string) {
  let result = role.replaceAll("_", " ").toLowerCase();
  result = result[0].toUpperCase() + result.slice(1);
  return result;
}

/**
 * Merges objects in the provided array based on their endTime property.
 *
 * @param data - The array of objects to be merged.
 * @returns An array of merged Event summary objects.
 */
export function mergeObjectsByEndTime(data: EventSummary[]): EventSummary[] {
  const mergedData: any = {};

  data.forEach((obj: any) => {
    const { endTime, category, objectType, startTime, count } = obj;

    if (count === undefined) {
      return; // Skip objects that don't have a count field
    }

    if (mergedData[endTime]) {
      mergedData[endTime].count += count;
    } else {
      mergedData[endTime] = {
        category,
        endTime,
        objectType,
        startTime,
        count
      };
    }
  });

  return Object.values(mergedData);
}

/**
 * Returns the number of digits after the decimal point in a given number.
 *
 * @param {number} number - The number to check.
 * @returns {number} The number of digits after the decimal point, or 0 if the number is null or undefined.
 */
export const getNumberOfDigitsAfterDecimal = (number: number): number => {
  if (!number) return 0;

  // Convert the number to a string and find the index of the decimal point.
  const strValue = number.toString();
  const index = strValue.indexOf(".");

  // If the number is an integer (i.e., does not have a decimal point), return 0.
  if (index === -1) {
    return 0;
  }

  // Calculate and return the number of digits after the decimal point.
  return strValue.length - index - 1;
};
