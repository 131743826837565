/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import { mergeObjectsByEndTime } from "~/helpers/utils";
import { eventServices } from "~/services";
import { EventSummary, EventsSummaryQuery } from "~/types/event";
import { updateURL } from '~/helpers/history';
interface timelineData {
  timestamp: string;
  bytes: number;
  rate: number;
  event?: number;
}

interface SelectedPanelProps {
  type: string;
  nodeID: string;
}

export enum TabTypes {
  networkMap = 'Network Map',
  events = 'Events',
}

type initState = {
  timelineData: timelineData[];
  selectedPanel: SelectedPanelProps;
  leafNodeID: string;
  selectedTimestamp: number;
  selectedEventTimestamp: number;
  loading: boolean;
  eventData: EventSummary[];
  vendorType: string;
  activeTab: TabTypes;
  lastUpdatedAt: number;
};

export const initialState: initState = {
  timelineData: [],
  selectedPanel: {
    type: "",
    nodeID: "",
  },
  leafNodeID: "",
  selectedTimestamp: 0,
  selectedEventTimestamp: 0,
  loading: true,
  eventData: [],
  vendorType: "",
  activeTab: TabTypes.networkMap,
  lastUpdatedAt: 0,
};

export const fetchEvents = createAsyncThunk(
  "itDashboard/fetchEvents",
  async ({ startTime, endTime, interval }: { startTime: number; endTime: number; interval: number }) => {
    const payload: EventsSummaryQuery = {
      category: "FAILURE",
      objectType: "ALL",
      interval: {
        startTime,
        endTime,
      },
      aggregationInterval: Math.round(interval),
    }
    const response = await eventServices.getEventSummary(payload);
    const mergedData = mergeObjectsByEndTime(response);
    return mergedData;
  }
);

const itDashboardSlice = createSlice({
  name: "itDashboard",
  initialState,
  reducers: {
    setTimelineData: (state, action) => {
      state.timelineData = action.payload;
    },
    setSelectedPanel: (state, action) => {
      state.selectedPanel = action.payload;
    },
    setLeafNodeID: (state, action) => {
      state.leafNodeID = action.payload;
    },
    setSelectedTimestamp: (state, action) => {
      state.selectedTimestamp = action.payload;
      updateURL({timestamp: action.payload});
    },
    setSelectedEventTimestamp: (state, action) => {
      state.selectedEventTimestamp = action.payload;
    },
    setVendorType: (state, action) => {
      state.vendorType = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.eventData = action.payload;
        state.loading = false;
        state.lastUpdatedAt = Date.now();
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        state.eventData = [];
        state.lastUpdatedAt = Date.now();
        console.error(action.error);
      });
  },
});

export const itDashboardAction = itDashboardSlice.actions;
export default itDashboardSlice.reducer;

export const timelineData = (state: RootState) => state.itDashboard.timelineData;
export const selectedPanel = (state: RootState) => state.itDashboard.selectedPanel;
export const selectedLeafNodeID = (state: RootState) => state.itDashboard.leafNodeID;
export const selectedTimestamp = (state: RootState) => state.itDashboard.selectedTimestamp;
export const selectedEventTimestamp = (state: RootState) => state.itDashboard.selectedEventTimestamp;
export const eventData = (state: RootState) => state.itDashboard.eventData;
export const loading = (state: RootState) => state.itDashboard.loading;
export const vendorType = (state: RootState) => state.itDashboard.vendorType;
export const activeTab = (state: RootState) => state.itDashboard.activeTab;
export const lastUpdatedAt = (state: RootState) => state.itDashboard.lastUpdatedAt;