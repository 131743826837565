import { i as e } from "./styled-832b6886.js";
import { r as a } from "./createSvgIcon-4b11b20c.js";
import o from "react/jsx-runtime";
var r = {}, t = e;
Object.defineProperty(r, "__esModule", {
  value: !0
});
var u = r.default = void 0, i = t(a()), d = o, f = (0, i.default)(/* @__PURE__ */ (0, d.jsx)("path", {
  d: "M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
}), "KeyboardArrowDown");
u = r.default = f;
export {
  u as d
};
